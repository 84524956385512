import { useContext } from "react";
import { AttentionBox, Button, Flex, Text } from "monday-ui-react-core";
import MyWeekIcon from "monday-ui-react-core/dist/icons/MyWeek";
import { Heading } from "monday-ui-react-core/next";
import ScheduleContext from "../../context/ScheduleContext";

const OAuthScreen = () => {
  const { oauthFlowInitiated, setOAuthFlowInitiated, startOAuthFlow, isUserViewerOrGuest } =
    useContext(ScheduleContext);

  return (
    <>
      {oauthFlowInitiated && (
        <AttentionBox
          className="monday-storybook-attention-box_box full-width"
          onClose={() => setOAuthFlowInitiated(false)}
          text="Please refresh this window after granting the permissions"
          title="Important"
          type="warning"
        />
      )}

      <Flex direction="Column" gap={40} className="permission-box">
        <Flex gap={10}>
          <Heading weight="light" type="h2">
            Schedule Recurring Tasks
          </Heading>
          <MyWeekIcon size={30} />
        </Flex>

        <Text element="p" type="text1">
          This app requires your permission to create tasks / items in your board as per your set
          task schedules.
        </Text>

        <Button color="primary" onClick={() => startOAuthFlow(true)} disabled={isUserViewerOrGuest}>
          Review Permissions
        </Button>
      </Flex>
    </>
  );
};

export default OAuthScreen;
