import { useContext } from "react";
import { TableRow, TableCell, Label, Button, Flex, Loader } from "monday-ui-react-core";
import SchedulesListContext from "../../context/SchedulesListContext";
import { recurrenceText, userFriendlyNextScheduledDate } from "../../utils/utils";

const ScheduleRow = ({ schedule, setInputBoardId, setInputItemId, disabled }) => {
  const { scheduleUnderDeletion, isDeleting, isUserViewerOrGuest, deleteSchedule } =
    useContext(SchedulesListContext);

  const {
    scheduleId,
    boardId,
    boardName,
    itemId,
    itemName,
    recurrence,
    action: { notificationEnabled },
    isActive,
    itemNotFound,
  } = schedule;

  return (
    <TableRow>
      <TableCell>{itemNotFound ? "[ITEM NOT FOUND]" : boardName}</TableCell>
      <TableCell>{itemNotFound ? "[ITEM NOT FOUND]" : itemName}</TableCell>
      <TableCell>{recurrenceText(recurrence)}</TableCell>
      <TableCell>{userFriendlyNextScheduledDate(recurrence)}</TableCell>
      <TableCell>
        <Label
          color={isActive ? "positive" : ""}
          labelClassName={!isActive ? "purple-bg" : ""}
          text={isActive ? "Active" : "Inactive"}
          isAnimationDisabled
        />
      </TableCell>
      <TableCell>
        <Label
          color={notificationEnabled ? "positive" : "negative"}
          text={notificationEnabled ? "ON" : "OFF"}
          kind="LINE"
          isAnimationDisabled
        />
      </TableCell>
      <TableCell>
        {isDeleting && scheduleUnderDeletion === scheduleId ? (
          <Loader size={20} />
        ) : (
          <Flex gap={5}>
            {!itemNotFound && (
              <Button
                size="SMALL"
                className="row-button"
                color="primary"
                onClick={() => {
                  setInputBoardId(boardId);
                  setInputItemId(itemId);
                }}
                disabled={disabled || isDeleting}
              >
                Edit
              </Button>
            )}
            <Button
              size="SMALL"
              className="row-button"
              color="negative"
              onClick={() => deleteSchedule(schedule)}
              disabled={isUserViewerOrGuest || isDeleting}
            >
              Delete
            </Button>
          </Flex>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ScheduleRow;
